.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flexCenter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flexBaseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.flexSpacer {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.FullHeight {
  height: 100%;
}

.FormMaxWidth {
  max-width: 75%;
}

.SearchContainer {
  width: 95%;
  margin: auto;
}

.formContainer button {
  margin: 8px;
}

.ListLink ul {
  padding: 0;
  margin: 10px;
}

.ListLink ul .flexContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ListLink ul a {
  color: inherit;
  text-decoration: inherit;
  width: 100%;
  height: 100%;
}

.ListLink ul li {
  list-style-type: none;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.ListLink ul li:first-of-type {
  border-top: 1px solid lightgray;
}

.ListLink ul li:hover {
  background-color: lightgray;
  color: white;
}

.ListLink ul li input[type="number"] {
  height: 100%;
  padding: 5px;
  width: 5%;
}

.visitForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.standardLink:hover {
  background-color: gray !important;
}

.activeLink {
  background-color: darkblue !important;
  color: white;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.TableStaffCell {
  width: 125px;
}

.ProductModal {
  width: 50%;
  height: 50%;
}

.ProductQuantity {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ProductQuantity p {
  padding: 0px 10px;
}

.NoBottom {
  border: none;
}

.data-grid {
  width: 100%;
}

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  z-index: 10000;
}

.Loading .Text {
  z-index: 1;
  background: #08543f;
  padding: 0;
  margin: 0;
  color: white;
}

.Loading .Text h1 {
  z-index: 1;
  padding: 0;
  margin: 0;
  margin: 10px 20px;
}

.Loading .Background {
  opacity: 0.5;
  background: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.Hidden {
  display: none !important;
}

.Selected {
  border: 1px solid blue;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
input[type="date"] {
  background-color: #0080ff;
  padding: 8px;
  top: 50%;
  left: 50%;
  font-family: "Roboto Mono", monospace;
  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}
::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}
/*# sourceMappingURL=styles.css.map */
